import React from "react";
import { Link } from "react-router-dom";
import logo from "../images/logoPj.png";
import styles from "./Footer.module.css";
import emailImg from "../images/email.png";
import locImg from "../images/location.png";
import mobImg from "../images/mobile.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedin,
  faFacebook,
  faYoutube,
  faWhatsapp,
  faTelegram
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    
    <footer className={styles.footer}>
      <div className={styles["container-top"]}>
        <div className={styles["footer-top"]}>
          <div className={styles["footer-brand"]}>
            <img src={logo} className={styles["footer-logo"]} alt="Company Logo" />
            <span className={styles.span}>PJSoftTech</span>  
          </div>
        </div>

        <div className={styles["container-bottom"]}>
          <div className={styles["footer-bottom"]}>
            <div className={styles["footer-services"]}>
              <div className={styles.services}><h4><span className={styles.span1}>Our Services</span></h4></div>
              <hr style={{ color: "red", width: "100%" }} />
              <div className={styles["services-grid"]}>
                <div className={styles["service-col"]}>
                  <ul>
                    <li>
                      <Link to="/student-information-system" style={{ color: "white", textDecoration: "none" }}>
                        Student Management System
                      </Link>
                    </li>
                    <li >
                      <Link to="/online-admission-system" style={{ color: "white", textDecoration: "none" }}>
                       <span className={styles.online_admission}> Online Admission System</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/fees-management-system" style={{ color: "white", textDecoration: "none" }}>
                        <span className={styles.fees_management}> Fees Management System </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/online-examination-system" style={{ color: "white", textDecoration: "none" }}>
                       <span className={styles.online_examination}> Online Examination System </span>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className={styles["service-col"]}>
                  <ul>
                    <li>
                      <Link to="/attendance-management-system" style={{ color: "white", textDecoration: "none" }}>
                        Attendance Management System
                      </Link>
                    </li>
                    <li>
                      <Link to="/higher-education-system" style={{ color: "white", textDecoration: "none" }}>
                        <span className={styles.higher_education}></span> Higher Education System
                      </Link>
                    </li>
                    <li>
                      <Link to="/university-management-software" style={{ color: "white", textDecoration: "none" }}>
                        University Management Software
                      </Link>
                    </li>
                    <li>
                      <Link to="/institute-management-software" style={{ color: "white", textDecoration: "none" }}>
                        <span className={styles.institute_management}> Institute Management Software </span>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className={styles["service-col"]}>
                  <ul>
                    <li>
                      <Link to="/college-management-software" style={{ color: "white", textDecoration: "none" }}>
                        College Management Software
                      </Link>
                    </li>
                    <li>
                      <Link to="/school-management-software" style={{ color: "white", textDecoration: "none" }}>
                        <span className={styles.school_management}> School Management Software </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/faculty-management-software" style={{ color: "white", textDecoration: "none" }}>
                        <span className={styles.faculty}> Faculty Management System </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/learning-management-software" style={{ color: "white", textDecoration: "none" }}>
                        Learning Management System
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className={styles["footer-contact"]}>
              <h4><span className={styles.span}>Contact Info</span></h4>
              <hr style={{ color: "red", width: "55%", marginTop: "5%", marginLeft:"20%" }} />
             
              <p>
                <img className={styles.imgSize } src={locImg} alt="Location Icon" />
                <i class="fa-solid fa-location-dot"></i>
                
                <a
                  href="https://www.google.com/maps?q=PjSoftTech+pvt+ltd,+Lokmanya+Bal+Gangadhar+Tilak+Rd,+Sadashiv+Peth,+Pune,+Maharashtra+411030"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "black", textDecoration: "none" }} className={styles.address}
                >
                 <h6 className="fw-bold"> PjSoftTech Pvt Ltd,</h6>
                  
                 203, 2nd floor, Mangalmurti Complex,
                   
                    behind ABIL Tower,                  
                  
                  hirabagh chowk, Tilak Road,
                  
                  Shrukravar Peth, Pune-411002,
                  
                  India
                </a>
              </p>
             
              <p>
                <img className={styles.imgSize} src={emailImg} alt="Email Icon" />
                <a href="mailto:pjsofttech@gmail.com" style={{ color: "black", textDecoration: "none" }}>
                info@pjsofttech.com
                </a>
              </p>
              <p>
                <img className={styles.imgSize} src={mobImg} alt="Mobile Icon" />
                <a href="tel:+919923570901" style={{ color: "black", textDecoration: "none" }}>
                  (+91) 99235 70901
                </a>  
              </p>
              

              <div className={styles["footer-social-icons"]}>
            <FontAwesomeIcon
              icon={faLinkedin}
              className={styles.spacebtwicon}
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/company/pjsofttech/",
                  "_blank"
                )
              }
            />
            <FontAwesomeIcon
              icon={faFacebook}
              className={styles.spacebtwicon}
              onClick={() =>
                window.open("https://www.facebook.com/profile.php?id=61555475266348", "_blank")
              }
            />
            <FontAwesomeIcon
              icon={faYoutube}
              className={styles.spacebtwicon}
              onClick={() =>
                window.open("https://www.youtube.com/@PJsofttech", "_blank")
              }
            />
            <FontAwesomeIcon
              icon={faWhatsapp}
              className={styles.spacebtwicon}
              onClick={() =>
                window.open("https://wa.me/9923570901", "_blank")
              }
            />
            <FontAwesomeIcon
              icon={faTelegram}
              className={styles.spacebtwicon}
              onClick={() =>
                window.open("https://t.me/YourTelegramHandle", "_blank")
              }
            />
          </div>


            </div>
          </div>
        </div>

        <div className={styles["footer-legal"]}>
          <div className={styles["legal-links"]}>
            <Link to="/privacy-policy" className={styles["legal-link"]}>Privacy Policy</Link>
            <Link to="/terms-and-conditions" className={styles["legal-link"]}>Terms and Conditions</Link>
            <Link to="/copyright-policy" className={styles["legal-link"]}>Copyright Policy</Link>
            <Link to="/data" className={styles["legal-link"]}>Data Protection Addendum</Link>
            <Link to="/refund" className={styles["legal-link"]}>Refund Policy</Link>
          </div>
        </div>
        <p className={styles["footer-copyright"]} style={{ color: "black" }}>
        &copy; 2024 PJSoftTech. All rights reserved.
      </p>
      </div>
      
      <div>
      
      </div>


      


    </footer>

    
    
  );
};

export default Footer;