import React, { useState } from 'react';
import { Navbar, Nav, NavDropdown, Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Navbar.css';

function MyNavbar() {
  const [expanded, setExpanded] = useState(false);

  const logos = {
    "student-information-system": "https://w7.pngwing.com/pngs/869/208/png-transparent-student-information-system-education-eth-zurich-school-student-class-people-logo-thumbnail.png",
    "online-admission-system": "https://cdn.iconscout.com/icon/premium/png-256-thumb/online-customer-help-support-guide-advice-communication-agent-3-5299.png",
    "fees-management-system": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRgL1DVOpi5fjkHQFa92mU8zuDDs4eKnLO0SzSxdMRt96ouTGt6ywz9tpCfm_zzcFktyWQ&usqp=CAU",
    "online-examination-system": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQWkBQ5NdjmR9DsIKFwR1KZITeFj1Vl6TX5Fg&s",
    "attendance-management-system": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNZPbfN_Cb9U4idVwjAedUl529ZzI5A9kHQw&s",
    "higher-education-system": "https://www.shutterstock.com/image-vector/vector-line-icon-alumni-600nw-1972745573.jpg",
    "university-management-software": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRDexaL7FQNLWmr-wq_Yx2sGq9S7B0x2RaU7Q&s",
    "institute-management-software": "https://static.vecteezy.com/system/resources/previews/005/848/528/original/progress-monitoring-gradient-linear-icon-investment-in-growth-collaborative-process-for-work-project-thin-line-color-symbol-modern-style-pictogram-isolated-outline-drawing-vector.jpg",
    "college-management-software": "https://w7.pngwing.com/pngs/609/924/png-transparent-decision-making-computer-icons-management-hoi.png",
    "school-management-software": "https://thumbs.dreamstime.com/b/employee-attendance-icon-job-ontime-vector-design-human-avatar-wall-clock-displaying-employee-schedule-icon-abstract-vector-242385430.jpg",
    "faculty-management-software": "https://cdn-icons-png.freepik.com/512/8161/8161813.png",
    "learning-management-software": "https://thumbs.dreamstime.com/b/learning-path-online-platform-color-icon-vector-illustration-sign-isolated-symbol-298318577.jpg"
  };

  const services = [
    { name: "Student Management System", path: "/student-information-system" },
    { name: "School Management Software", path: "/school-management-software" },
    { name: "Online Admission System", path: "/online-admission-system" },
    { name: "Fees Management System", path: "/fees-management-system" },
    { name: "Employee Management System", path: "/faculty-management-software" },
    { name: "Online Examination System", path: "/online-examination-system" },
    { name: "Attendance Management System", path: "/attendance-management-system" },
    { name: "Higher Education System", path: "/higher-education-system" },
    { name: "University Management Software", path: "/university-management-software" },
    { name: "Institute Management Software", path: "/institute-management-software" },
    { name: "College Management Software", path: "/college-management-software" },
    { name: "Enquiry Management System", path: "/learning-management-software" }
  ];

  const handleNavbarToggle = () => setExpanded(!expanded);

  const handleServiceClick = () => setExpanded(false); // Close navbar after service link clicked

  return (
    <Navbar className="navbar-custom pt-3" expand="lg" fixed="top" expanded={expanded}>
  <Container fluid> {/* Changed to fluid container */}
    <Navbar.Brand as={Link} to="/" className="d-flex align-items-center navbar-left">
      <img
        src="/logoPj.png"  // Path to the logo image in the public directory
        alt="Logo"
        className="d-inline-block align-top" width={'120'}
      />
      <span className="company-name fs-5">PJSoftTech</span>
    </Navbar.Brand>
    <Navbar.Toggle onClick={handleNavbarToggle} aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end text-pop fs-6 fw-bold">
      <Nav>
        <Nav.Link className="home-link" as={Link} to="/" onClick={() => setExpanded(false)}>Home</Nav.Link>
        <NavDropdown className="multi-column-dropdown services-link" title="Services" id="basic-nav-dropdown">
          <div className="dropdown-menu-multi-column">
            {services.map((service, index) => (
              <div className="dropdown-item-container" key={service.path}>
                <NavDropdown.Item
                  as={Link}
                  to={service.path}
                  onClick={handleServiceClick} // Close navbar after service link clicked
                >
                  <img src={logos[service.path.slice(1)]} alt={service.name} className="dropdown-logo" /> {service.name}
                </NavDropdown.Item>
              </div>
            ))}
          </div>
        </NavDropdown>
        <Nav.Link className="home-link" as={Link} to="/solutions" onClick={() => setExpanded(false)}>Solutions</Nav.Link>
        <Nav.Link className="home-link" as={Link} to="/blogs" onClick={() => setExpanded(false)}>Blogs</Nav.Link>
        <Nav.Link className="home-link" as={Link} to="/organization" onClick={() => setExpanded(false)}>Organization</Nav.Link>
        <Nav.Link className="home-link" as={Link} to="/team" onClick={() => setExpanded(false)}>Team</Nav.Link>
        <Nav.Link className="home-link" as={Link} to="/plan" onClick={() => setExpanded(false)}>Plan</Nav.Link>
        <Nav.Link className="home-link" as={Link} to="/about" onClick={() => setExpanded(false)}>About</Nav.Link>
        <Nav.Link className="home-link" as={Link} to="/contact-us" onClick={() => setExpanded(false)}>Contact Us</Nav.Link>
        <Nav.Link>
          <Button as={Link} to="/contact-us" className="Btn fs-7 rounded-2 fw-bold ">Free Demo</Button>
        </Nav.Link>
        <Nav.Link>
        <Button
          className="Btn fs-6 rounded-2 fw-bold"
           onClick={() => window.location.href = "http://pjsofttech.in/"}
          >
            Log in
          </Button>

        </Nav.Link>
      </Nav>
    </Navbar.Collapse>
  </Container>
</Navbar>

  );
}

export default MyNavbar;
